import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../../contexts/Api';
import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { cleanObject, handleApi, printQuotation, generateQuotationHtml} from '../../../../utils/utils'; 

const NewQuotation = ({ onClose }) => {
    const [quotation, setQuotation] = useState({ 
        products: [{ 
            product_id: null, 
            unit_price: null, 
            discount: null, 
            quantity: null 
        }] 
    });
     const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [ContactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [price, setPrice] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCustomers();
        fetchProducts();
    }, []);

    const fetchCustomers = async () => {
        await fetchData('customers', userData, setCustomers);
        setIsLoading(false);
    };

    const fetchProducts = async () => {
        if (userData) {
            await fetchData('products', userData, setProducts);
            setIsLoading(false);
        } else {
            console.error('userData or token is undefined');
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setQuotation(prevQuotation => {
            const updatedQuotation = { ...prevQuotation };

            switch (name) {
                case 'customerName':
                    setCustomerName(value);
                    const selectedCustomer = customers.find((customer) => customer.name === value);
                    if (selectedCustomer) {
                        updatedQuotation.customer = selectedCustomer;  // Add the entire customer object
                        updatedQuotation.customer_id = selectedCustomer.id;
                        setCustomer(selectedCustomer);
                        setPhone(selectedCustomer.phone_number);
                        setContactPerson(selectedCustomer.contact_person);

                        if (!updatedQuotation.products.length && updatedQuotation.date) {
                            updatedQuotation.products.push({ name: '', price: 0, discount: 0, qty: 1, total: 0 });
                        }
                    } else {
                        toast.error('Customer does not exist');
                        setCustomerName('');
                        setPhone('');
                        setContactPerson('');
                    }
                    break;

                case 'date':
                    updatedQuotation[name] = value;

                    if (updatedQuotation.customer_id && !updatedQuotation.products.length) {
                        updatedQuotation.products.push({ name: '', price: 0, discount: 0, qty: 1, total: 0 });
                    }
                    break;

                default:
                    break;
            }
            return updatedQuotation;
        });
    };

    const handleProductChange = (index, field, value) => {
        setQuotation(prevQuotation => {
            const updatedProducts = prevQuotation.products.map((product, i) => {
                if (i === index) {
                    const updatedProduct = { ...product, [field]: value };

                    // Fetch price if product name is updated
                    if (field === 'name') {
                        const selectedProduct = products.find(p => p.name === value);
                        if (selectedProduct) {
                            updatedProduct.product_id = selectedProduct.id;
                            updatedProduct.price = selectedProduct.prices.sell_price_new;
                            updatedProduct.discount = selectedProduct.discount || 0;
                            updatedProduct.total = (updatedProduct.price * updatedProduct.qty) - updatedProduct.discount;
                        } else {
                            updatedProduct.product_id = null;
                            updatedProduct.price = 0;
                            updatedProduct.total = 0;
                        }
                    }

                   // Recalculate total if price, qty, or discount is updated
                    if (['price', 'qty', 'discount'].includes(field)) {
                        const price = parseFloat(updatedProduct.price) || 0;
                        const qty = parseFloat(updatedProduct.qty) || 1;
                        const discountPercentage = parseFloat(updatedProduct.discount) || 0;
                        // Calculate the discount amount
                        const discountAmount = (price * discountPercentage) / 100;
                        // Calculate the total
                        updatedProduct.total = (price * qty) - (discountAmount * qty);
                    }


                    return updatedProduct;
                }
                return product;
            });

            // Check for duplicate product
            const isProductDuplicate = updatedProducts.some((prod, i) => prod.name === updatedProducts[index].name && i !== index);

            if (!isProductDuplicate) {
                // Add a new product line if the current product line is filled and total is greater than 0
                const lastProduct = updatedProducts[updatedProducts.length - 1];
                if (lastProduct && lastProduct.name && lastProduct.total > 0) {
                    updatedProducts.push({ name: '', price: 0, discount: 0, qty: 1, total: 0 });
                }
            } else {
                toast.error('Product is already in quotation.');
            }

            return { ...prevQuotation, products: updatedProducts };
        });
    };

    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your Quotation');
            formData.append('text', 'Please find the attached quotation.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };
    
    
    
   /* const printQuotation = (quotation) => {
        const printWindow = window.open('', '', 'height=800,width=1200');
        if (!printWindow) {
          console.error('Failed to open print window.');
          return;
        }
      
        const htmlContent = generateQuotationHtml(quotation);
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
      }; */
      

    const handlePrint =  () => {
        const updatedQuotation = { ...quotation, companyProfile};
        printQuotation(updatedQuotation)
       
    }

    const handleDownload = async () => {
        //const quotation = getQuotationData(); // Replace with your actual data-fetching logic
        // Generate the PDF document
        const blob = await pdf(<MyDocument quotation={quotation} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'quotation.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
            const blob = await pdf(<MyDocument quotation={quotation} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'quotation.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('quotation sir ',quotation)
        const updatedQuotation = {
            customer_id: quotation.customer_id,
            date: quotation.date,
            transactionLines: quotation.products.map(product => ({
                product_id: product.product_id,
                unit_price: product.price,
                quantity: product.qty,
                discount: product.discount,
            }))
        };
        
        
        const cleanedQuotation = cleanObject(updatedQuotation);
        console.log('Cleaned Quotation:', cleanedQuotation);
   
        setIsLoading(true);
        const apiMethod = 'POST';
    
        try {
            const response = await handleApi('sales/quotations', cleanedQuotation, userData, apiMethod);
            console.log('API Response:', response); // Log the response
    
            if (response.status === 200) {
                toast.success('Quotation added  successfully.');
                setActionCount(actionCount + 1);
               //resetForm();
            } else if (response.status === 409) {
                toast.error('Quotationalready exists.');
            } else {
                toast.error('Error saving Quotation.', response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving Quotation: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
       // resetForm()
   ;
    };

    const handleClose = () => {
        navigate(-1);
    };

    const shouldShowProductLinesHeader = quotation.date && quotation.customer_id;
    const isTotalGreaterThanZero = quotation.products.reduce((total, product) => total + product.total, 0) > 0;
    // Determine if the role is not Officer, Admin, or Public
    // Normalize the role to lowercase for comparison
    const userRole = userData?.data?.role?.role?.toLowerCase();
    const allowedRoles = ['officer', 'admin', 'public'];  // Use lowercase for comparison

    // Determine if the role is not one of the allowed roles
    const isRoleAllowed = userRole && !allowedRoles.includes(userRole);


    return (
        <>
            <div className="">
                <div className="flex">
                    <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Quotation</h2>
                </div>
                <div>
                    {isLoading ? (
                        <div className="flex items-center flex-col">
                            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                            <p className="mt-2">Processing. Please wait...</p>
                        </div>
                    ) : (
                        <>
                            {/* menu icons  */}
                            <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:px-4 py-2 flex flex-row justify-end">
                            {/* Conditionally render the Cancel button */}
                            {/* {quotation.products.length > 0 && isTotalGreaterThanZero && (
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Cancel"
                                    onClick={handleCancel}
                                >
                                    <FcCancel size={20} color={currentColor} />
                                </button>
                            )} */}

                            

                            {/* {quotation.products.length > 0 && isTotalGreaterThanZero && isRoleAllowed && (
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Approve"
                                >
                                    <FcApproval size={20} color={currentColor} />
                                </button>
                            )} */}
                            {/* {quotation.products.length > 0 && isTotalGreaterThanZero &&  (
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Print"
                                    onClick={handlePrint}
                                >
                                    <MdPayment size={20} color={currentColor} />
                                </button>
                            )} */}

                                {/* <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Email"
                                    onClick={handleDownload}    
                                    
   
                                >
                                    <IoMdDownload  size={20} color={currentColor} />
                                </button> */}

                           
                                {/* <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Email"
                                    onClick={handleEmail}    
                                    
   
                                >
                                    <MdAttachEmail size={20} color={currentColor} />
                                </button> */}

                            
    
                            

                            {quotation.products.length > 0 && isTotalGreaterThanZero && (
                            
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Submit"
                                    onClick={handleSubmit}
                                >
                                    <BsFillSendFill size={20} color="blue" />
                                </button>
                            )}
                            </div>


                            <div className="mb-2 mx-2 md:mx-1 py-2 md:py-2 bg-gray-100 rounded-3xl">
                                <div className="w-full px-4 flex flex-col">
                                    <div className="flex items-center mb-2">
                                        <p className="whitespace-nowrap text-slate-500 w-24">Date</p>
                                        <div className="flex-1 p-1 px-4 rounded-3xl">
                                            <input
                                                type="date"
                                                name="date"
                                                value={quotation.date || ''}
                                                onChange={handleTextChange}
                                                className="mt-1 px-4 py-1 w-full rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center mb-2">
                                        <p className="whitespace-nowrap text-slate-500 w-24">Customer</p>
                                        <div className="flex-1 p-1 px-4 rounded-2xl">
                                            <input
                                                type="text"
                                                name="customerName"
                                                value={customerName}
                                                onChange={handleTextChange}
                                                className="px-2 py-1 w-full rounded-lg border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                list="customersList"
                                            />
                                            <datalist id="customersList">
                                                {customers && customers.map((customer, index) => (
                                                    <option key={index} value={customer.name} />
                                                ))}
                                            </datalist>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>

                            {shouldShowProductLinesHeader && (
                                <>
                                    <div className="flex justify-center items-center">
                                        <p className="whitespace-nowrap mb-1 text-slate-500 text-center">Product Lines</p>
                                    </div>

                                    <div className="mx-2 md:mx-1 py-0 md:py-0 bg-blue-500 rounded-3xl">
                                        <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col">
                                            {/* Table Header */}
                                            <div className="flex items-center font-semibold mb-2 text-white">
                                                <div className="w-1/12 text-center"></div>
                                                <div className="w-1/3">Product Name</div>
                                                <div className="w-1/6 text-right mx-2">Price</div>
                                                <div className="w-1/6 mx-2">Quantity</div>
                                                <div className="w-1/6 mx-2">Discount</div>
                                                <div className="w-1/6 text-right">Total</div>
                                            </div>

                                            <div className="space-y-2">
                                                {quotation.products.map((product, index) => (
                                                    <div key={index} className="flex items-center mb-2 text-white">
                                                        <div className="w-1/12 text-center font-semibold">{index + 1}</div> 
                                                        <input
                                                            type="text"
                                                            value={product.name}
                                                            onChange={(e) => handleProductChange(index, 'name', e.target.value)}
                                                            className="px-4 py-1 w-1/3 rounded-lg border  font-semibold text-black border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            list="productList"
                                                            placeholder="Product Name"
                                                        />
                                                        <datalist id="productList">
                                                            {products && products.map((product, idx) => (
                                                                <option key={idx} value={product.name} />
                                                            ))}
                                                        </datalist>
                                                        <div className="w-1/6 text-right font-semibold">
                                                            MK {product?.price}
                                                        </div>
                                                        <input
                                                            type="number"
                                                            value={product.qty}
                                                            onChange={(e) => handleProductChange(index, 'qty', e.target.value)}
                                                            className="px-4 py-1 w-1/6 text-right text-black  font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            placeholder="Qty"
                                                        />
                                                        <input
                                                            type="number"
                                                            value={product.discount}
                                                            onChange={(e) => handleProductChange(index, 'discount', e.target.value)}
                                                            className="px-4 py-1 w-1/6 text-right text-black  font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            placeholder="Disc"
                                                        />
                                                        <div className="w-1/6 text-right font-semibold">
                                                        <div>
                                                             MK {typeof product.total === 'number' ? product.total.toFixed(2) : '0.00'}
                                                        </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Grand Total */}
                                            <div className="flex items-center font-semibold mt-4 text-white">
                                                <div className="w-1/12 text-center"></div>
                                                <div className="w-1/3"></div>
                                                <div className="w-1/6"></div>
                                                <div className="w-1/6"></div>
                                                <div className="w-1/6 text-right">Total:</div>
                                                <div className="w-1/6 text-right font-semibold">
                                                    MK {quotation.products.reduce((total, product) => total + product.total, 0).toFixed(2)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {quotation.products.length > 0 && isTotalGreaterThanZero && (
                                        <div className="flex justify-end p-2">
                                            <button
                                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </button>
                                    </div>
                                    
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default NewQuotation;
