import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { IoMdMore } from "react-icons/io";

import { Layout, Header, Modal, NewPurchaseOrder, EditPurchaseOrder } from '../../../../components';
import { handleApi, formatCurrency,formatFriendlyDate } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Orders = () => {
  const [POs, setPOs] = useState([]); // purchase orders
  const [PO, setPO] = useState({}); // one purchase order
//   const [quantityAdjustment, setQuantityAdjustment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPurchaseOrders();
  }, [actionCount]);

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when searchTerm changes
  }, [searchTerm]);

  const fetchPurchaseOrders = async () => {
    const apiEndpoint = 'purchase/orders';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
     
      if (status === 200) {
        setPOs(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredPOs = POs.filter(po => {
    const partnerName = po?.partners?.name;
    return partnerName && partnerName.toLowerCase().includes(searchTerm.toLowerCase());
  });
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPOs.slice(indexOfFirstItem, indexOfLastItem);



  const totalPages = Math.ceil(filteredPOs.length / itemsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleAction = (action, po) => {

   setActionType(action)
   setPO(po)
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };
console.log('purchase order',POs)
  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Purchase Orders" />
        </div>

        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && (
            <NewPurchaseOrder data={filteredPOs} onClose={handleCloseModal} showCloseButton={true} />
          )}
          {isModalOpen  && actionType === 'edit' && (
            <EditPurchaseOrder originalPO={PO} onClose={handleCloseModal} />
          )}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl  mx-auto">
            <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">

   

   

<div className="max-w-lg flex justify-end items-center mb-2 mx-auto">

              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-2 py-1 rounded-full text-xs text-white focus:outline-none ${
                      currentPage === index + 1 ? 'bg-current' : ''
                    }`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
               {/* Search Input */}
            <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
            />
            </div>

     {/* New Quotation Button */}
     <button
      className="ml-4 px-3 py-2 text-xs font-medium rounded-lg text-white focus:outline-none flex items-center space-x-1"
      style={{  background: currentColor }}
      onClick={() => handleAction('new', {})}
    >
      <IoMdAdd />
      <span className="ml-1">New Purchase Order</span>
    </button>
  </div>
   {/*end  New Quotation Button */}


              </div>

              {/* Quotations List */}
              <div className="w-full bg-white rounded-lg shadow-lg">
                <div className="grid grid-cols-6 gap-4 p-3 border-b">
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Purchase Order#</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Date</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Customer</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Order Value</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Status</span>
                  </div>
                  {/* <div className="text-right">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Actions</span>
                  </div> */}
                </div>

                {currentItems.length > 0 ? (
                  currentItems.map((po, index) => (
                    
                    <div   onClick={() => handleAction('edit',po)} className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200">
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{indexOfFirstItem + index + 1}. {po?.reference ? po?.reference : 'No reference no'}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{po?.date ? formatFriendlyDate(po?.date) : 'No Date'}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{po?.partners?.name || 'N/A'}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{formatCurrency(po?.totalTaxedPrice)|| 'N/A'}</span>
                      </div>
                      <div className="text-center">
                        <span className={`text-sm text-white py-1 px-3 rounded-full uppercase ${po?.state === 'draft' ? 'bg-red-500' : 'bg-green-500'}`}>
                            {po?.state}
                        </span>
                        </div>

                      <div className="text-right">
                        <button
                          className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                          onClick={() => handleAction('edit',po)}
                          title="More detail"
                        >
                               <IoMdMore size={20} color={currentColor} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="p-3 text-center">No purchase order available.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Orders;
