
import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { CiEdit } from 'react-icons/ci';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../../contexts/Api';
import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { getChangedFields,handleApi, printQuotation, formatMediumDate, deepEqual} from '../../../../utils/utils'; 

const EditQuotation = ({ originalQuotation, onClose }) => {
    const [quotation, setQuotation] = useState(originalQuotation);
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]); // All products in the database for autocomplete fields
    const [newProductLine, setNewProductLine] = useState(false); // for tracking new product line
    const [hasProductListChanged, setHasProductListChanged] = useState(false); // for tracking new product line
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
      fetchCustomers();
      fetchProducts();
      
        console.log('Initial quotation fiest time ',originalQuotation);
    }, []);

   

    useEffect(() => {
        setQuotation(prevQuotation => ({
            ...prevQuotation,
            products: prevQuotation.products || []
        }));
    }, [originalQuotation]);

    const fetchCustomers = async () => {
        await fetchData('customers', userData, setCustomers);
        setIsLoading(false);
    };

    const fetchProducts = async () => {
        if (userData) {
            await fetchData('products', userData, setProducts);
            setIsLoading(false);
        } else {
            console.error('userData or token is undefined');
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;

        

        setQuotation(prevQuotation => {
            const updatedQuotation = { ...prevQuotation };

            switch (name) {
                case 'customerName':
                    setCustomerName(value);
                    const selectedCustomer = customers.find((customer) => customer.name === value);
                    if (selectedCustomer) {
                        updatedQuotation.customer = selectedCustomer; // Add the entire customer object
                        updatedQuotation.customer_id = selectedCustomer.id;
                        setCustomer(selectedCustomer);
                        setPhone(selectedCustomer.phone_number);
                        setContactPerson(selectedCustomer.contact_person);

                        if (!updatedQuotation.products.length && updatedQuotation.date) {
                            updatedQuotation.products.push({ name: '', price: 0, discount: 0, qty: 1, total: 0 });
                        }
                    } else {
                        toast.error('Customer does not exist');
                        setCustomerName('');
                        setPhone('');
                        setContactPerson('');
                    }
                    break;

                case 'date':
                    updatedQuotation[name] = value;

                    if (updatedQuotation.customer_id && !updatedQuotation.products.length) {
                        updatedQuotation.products.push({ name: '', price: 0, discount: 0, qty: 1, total: 0 });
                    }
                    break;

                default:
                    break;
            }
            return updatedQuotation;
        });
    };

    const addProductLine = () => { // Function to add a new blank product line
        setQuotation(prevQuotation => ({
            ...prevQuotation,
            products: [...prevQuotation.products, { name: '', price: 0, qty: 1, discount: 0, total: 0 }]
        }));
        setHasProductListChanged(!deepEqual(true));
        // setHasProductListChanged(!deepEqual(originalQuotation.products, quotation.products));
        setNewProductLine(true)
    };

    const removeProductLine = (index) => { // remove product from quotation
      setQuotation((prevQuotation) => {
          const updatedProducts = prevQuotation.products.filter((_, i) => i !== index);
          return {
              ...prevQuotation,
              products: updatedProducts,
          };
      });
  };

    const handleProductChange = (index, field, value) => {
        setQuotation(prevQuotation => {
            const updatedProducts = prevQuotation.products.map((product, i) => {
                if (i === index) {
                    const updatedProduct = { ...product, [field]: value };

                    // Fetch price if product name is updated
                    if (field === 'name') {
                        const selectedProduct = products.find(p => p.name === value);
                        if (selectedProduct) {
                            updatedProduct.price = selectedProduct.prices.sell_price_new;
                            updatedProduct.discount = selectedProduct.discount || 0;
                            updatedProduct.total = (updatedProduct.price * updatedProduct.qty) - updatedProduct.discount;
                        } else {
                            updatedProduct.price = 0;
                            updatedProduct.total = 0;
                        }
                    }

                    // Recalculate total if price, qty, or discount is updated
                    if (['price', 'qty', 'discount'].includes(field)) {
                        const price = parseFloat(updatedProduct.price) || 0;
                        const qty = parseFloat(updatedProduct.qty) || 1;
                        const discountPercentage = parseFloat(updatedProduct.discount) || 0;
                        // Calculate the discount amount
                        const discountAmount = (price * discountPercentage) / 100;
                        // Calculate the total
                        updatedProduct.total = (price * qty) - (discountAmount * qty);
                    }

                    return updatedProduct;
                }
                return product;
            });

            // Check for duplicate product
            const isProductDuplicate = updatedProducts.some((prod, i) => prod.name === updatedProducts[index].name && i !== index);

            if (!isProductDuplicate) {
              // Add a new product line if the current product line is filled and total is greater than 0
              const lastProduct = updatedProducts[updatedProducts.length - 1];
              if (lastProduct && lastProduct.name && lastProduct.total > 0) {
                updatedProducts.push({ name: '', price: 0, discount: 0, qty: 1, total: 0 });
                setNewProductLine(true)
              }
            } else {
              // Clear the duplicate product's fields
              updatedProducts[index] = { name: '', price: 0, discount: 0, qty: 1, total: 0 };
              toast.error('Product already exists in the quotation.');
            }

            return { ...prevQuotation, products: updatedProducts };
        });
    };

    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your Quotation');
            formData.append('text', 'Please find the attached quotation.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };

   

    
    const handlePrint = () => {
        const updatedQuotation = { ...quotation, companyProfile };
        printQuotation(updatedQuotation);
    };

    const handleDownload = async () => {
        // Generate the PDF document
        const blob = await pdf(<MyDocument quotation={quotation} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'quotation.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
            const blob = await pdf(<MyDocument quotation={quotation} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'quotation.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };

    const handleApproveBtnClick = () => {
      setQuotation(prevQuotation => {
        const updatedQuotation = { ...prevQuotation, state: 'approved' }; // Update the quotation with approved state
        const changedFields = getChangedFields(originalQuotation, updatedQuotation);   // Extract only changed fields
    
        // Filter out records without a name field
        const cleanedProducts = (changedFields.products || []).filter(product => product.name); // Remove record with no product name 
        const cleanedQuotation = { ...changedFields, products: cleanedProducts };
        const apiMethod = 'PUT';
        handleSubmit(null, cleanedQuotation,apiMethod);  // Pass null if no event
        return updatedQuotation; // Return the updated quotation
      });
    };

    const handleCancelBtnClick = () => {
      const changedFields = getChangedFields(originalQuotation, quotation); // Extract changed fields
      const cleanedQuotation = { id: changedFields.id }; // Only send the ID for deletion
      const apiMethod = 'DELETE';
      handleSubmit(null, cleanedQuotation, apiMethod); // Pass null if no event
  };
    
    

    const handleSubmit = async (e,cleanedQuotation,apiMethod) => {
      if (e && e.preventDefault) {e.preventDefault();} // Prevent default only if e is present
      console.log('cleaned quotation in submit ',cleanedQuotation)
      debugger
      setIsLoading(true);
      try {
          const response = await handleApi('sales/quotations', cleanedQuotation, userData, apiMethod);
          console.log('API Response:', response); // Log the response
          if (response.status === 200) {toast.success('Quotation updated  successfully.');
          }else {toast.error('Error saving Quotation.', response.statusText);}
      } catch (error) {
        toast.error('Error saving Quotation: ' + error.message);
      } finally {
        setActionCount(actionCount + 1);
        setIsLoading(false);
      }
  };




    const handleSaveBtnClick = async (e) => {
        e.preventDefault();
       
         const changedFields = getChangedFields(originalQuotation, quotation);   // Extract only changed fields
        // Filter out records without a name field
        const cleanedProducts = (changedFields.products || []).filter(product => product.name);

        // Create the cleaned quotation object
        const cleanedQuotation = {...changedFields,products: cleanedProducts};
        console.log('cleaned quotation',cleanedQuotation)
       // debugger
        setIsLoading(true);
        const apiMethod = 'PUT';
    
        try {
            const response = await handleApi('sales/quotations', cleanedQuotation, userData, apiMethod);
            console.log('API Response:', response); // Log the response
    
            if (response.status === 200) {
                toast.success('Quotation added successfully.');
                setActionCount(actionCount + 1);
                // resetForm();
            } else if (response.status === 409) {
                toast.error('Quotation already exists.');
            } else {
                toast.error('Error saving Quotation.', response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving Quotation: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
       // resetForm()
   };

    const handleClose = () => {
        navigate(-1);
    };

    const total = (quotation.products?.reduce((total, product) => total + product.total, 0) || 0).toFixed(2);

    const shouldShowProductLinesHeader = quotation?.date && quotation?.customer_id;
    const userRole = userData?.data?.role?.role?.toLowerCase();
    const allowedRoles = ['officer', 'admin', 'public']; // Use lowercase for comparison
    const isRoleAllowed = userRole && !allowedRoles.includes(userRole);

    
    console.log('has product changed',hasProductListChanged);
    return (
      <>
        <div className="">
          <div className="flex">
            <h2 className="text-blue-700  text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Quotation</h2>
          </div>
          <div>
          {isLoading ? (
            <div className="flex items-center flex-col">
              <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
              <p className="mt-2">Processing data. Please wait...</p>
            </div>
          ) : (
            <>
              {/* meneu icon */}
              <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
             
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Delete"
                    onClick={handleCancelBtnClick}
                  >
                    <FcCancel size={20} color={currentColor} />
                  </button>
             
                {quotation?.products &&  total > 0 && quotation.state ==='draft' && (
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Approve"
                  onClick={handleApproveBtnClick}
                >
                  <FcApproval size={20} color={currentColor} />
                </button>
                   )}
                {(!newProductLine || total === 0) && (
                        
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Edit"
                  onClick={addProductLine}
                >
                 
                  <CiEdit size={20} color={currentColor} />
                </button>
              
                )}
                

                {hasProductListChanged && Array.isArray(quotation.products) &&  total > 0 && (
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Save"
                    onClick={handleSubmit}
                  >
                    <BsFillSendFill size={20} color="blue" />
                  </button>
                )}
               
              </div>
              {/* end menu icons */}
              {/* Quantity adjustment  preamble info */}
              <div className="mb-2 mx-2 md:mx-1  py-2 md:py-2 bg-gray-50 rounded-3xl"> 
                <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col ">
                  <div className="flex flex-wrap justify-between gap-4 mb-4">
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Quotation #.</p>
                      <p className="whitespace-nowrap text-slate-500 font-semibold text-xs">
                        {quotation?.reference|| 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Date</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold">
                        {formatMediumDate(quotation?.date) || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Status</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold">
                        {quotation?.state || 'NA'}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-4">
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Customer</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold">
                        {quotation?.partners?.name || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Telephone</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold">
                          {quotation?.partners?.phone_number || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Contact Person</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold">
                          {quotation?.partners?.contact_person || 'NA'}
                      </p>
                    </div>
                  </div>  
                </div>
              </div>
              {/* end quantity adjust preamble info */}
        {/* Products lines  */}
        
          <>
            <div className="flex justify-center items-center">
              <p className="whitespace-nowrap mb-1 text-slate-500 text-center">Product Lines</p>
            </div>
            <div className="mx-2 md:mx-1 py-0 md:py-0 bg-blue-500 rounded-3xl">
              <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col">
                {/* Table Header */}
                <div className="flex items-center font-semibold mb-2 text-white">
                  <div className="w-1/12 text-center"></div>
                    <div className="w-1/3">Product Name</div>
                      <div className="w-1/6 text-right mr-4 ">Price</div>
                        <div className="w-1/6 mx-2">Quantity</div>
                        <div className="w-1/6 mx-2">Discount</div>
                        <div className="w-1/6 text-right">Total</div>
                        <div className="w-1/9 text-right"></div>
                      </div>

                      <div className="space-y-2">
                      
{/* Ensure `quotation` and `quotation.products` are defined and `quotation.products` is an array */}
{Array.isArray(quotation.products) && quotation.products.length > 0 ? (
  quotation.products.map((product, index) => (
    <div key={index} className="flex items-center mb-2 text-white">
      <div className="w-1/12 text-center font-semibold">{index + 1}</div>
      <input
        type="text"
        value={product.name || ''}
        onChange={(e) => handleProductChange(index, 'name', e.target.value)}
        className="px-2 py-1 w-1/3 rounded-lg border font-semibold text-black border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        list="productList"
        placeholder="Product Name"
      />
      <datalist id="productList">
        {Array.isArray(products) && products.map((p, idx) => (
          <option key={idx} value={p.name} />
        ))}
      </datalist>
      <div className="w-1/6 text-right font-semibold mr-2 ">
        MK {product.price || '0.00'}
      </div>
      <input
        type="number"
        value={product.qty || 0}
        onChange={(e) => handleProductChange(index, 'qty', e.target.value)}
        className="px-4 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        placeholder="Qty"
      />
      <input
        type="number"
        value={product.discount || 0}
        onChange={(e) => handleProductChange(index, 'discount', e.target.value)}
        className="ml-2 px-4 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        placeholder="Disc"
      />
      <div className="w-1/6 text-right font-semibold">
        MK {product.total ? product.total.toFixed(2) : '0.00'}
      </div>

      {/* Remove button */}
      <button onClick={() => removeProductLine(index)} className="ml-2 text-red-500">
        <AiOutlineCloseCircle color="gray"/>
      </button>
       {/* end Remove button */}
    </div>
  ))
) : (
  <div className="flex justify-center items-center h-32 bg-white rounded-lg">
    <p className="text-slate-500 text-lg">No products available</p>
  </div>
)}

                        </div>
                        {/* Grand Total */}
                        <div className="flex items-center font-semibold mt-4 text-white">
                          <div className="w-1/12 text-center"></div>
                          <div className="w-1/3"></div>
                          <div className="w-1/6"></div>
                          <div className="w-1/6"></div>
                          <div className="w-1/6 text-right">Total:</div>
                          <div className="w-1/6 text-right font-semibold">
                              MK {total}
                          </div>
                        </div>
                      </div>
                    </div>
                    {hasProductListChanged && (
          
                      <div className="flex justify-end p-2">
                        <button
                          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </>
                
                {/* end products line */}
              </>
            )}
          </div>
        </div>
      <ToastContainer />
    </>
  );
};

export default EditQuotation;
